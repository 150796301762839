<template>
    <div class="two-d-section d-flex flex-column">
        <div class="p-2 d-flex bannr flex-wrap">
            <div class="col-6">
                <div class="d-flex">
                    <p class="fw-400 fs-12 me-2 mb-0">ထိုးမည့်အချိန်</p>
                    <h1 class="fw-600 fs-16 mb-0">{{ selectedSection.opening_time }} <span class="fw-400 fs-12"></span>
                    </h1>
                </div>
                <div class="d-flex">
                    <p class="fw-400 fs-12 me-2 mb-0">ထီပိတ်ချိန်</p>
                    <h1 class="fw-600 fs-16 mb-0"><span>{{ countDown }} </span></h1>
                </div>

            </div>
            <div class="col-6 text-end">
                <div class="d-flex align-items-center justify-content-end">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-white" size="sm" />
                    <span class="fw-400 fs-12 mx-2">လက်ကျန်ငွေ</span>
                </div>
                <span class="fw-600 text-success">{{ $helper.formattedNumber(currentUser.balance) }} Ks</span>

            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-1">
                <router-link :to="{ name: 'hot-nums', params: { 'section': this.$route.params.section } }">
                    <font-awesome-icon class="text-danger mx-2" size="xl"
                        :icon="['fa-solid', 'fa-fire']"></font-awesome-icon>
                </router-link>
            </div>
            <div class="col-1">
                <router-link :to="{ name: 'block-nums', params: { 'section': this.$route.params.section } }">
                    <font-awesome-icon class="text-danger mx-2" size="xl"
                        :icon="['fa-solid', 'fa-circle-minus']"></font-awesome-icon>
                </router-link>
            </div>
            <div class="col-1">
                <router-link :to="{ name: 'ledger', params: { 'section': this.$route.params.section } }">
                    <font-awesome-icon class="text-primary mx-2" size="xl"
                        :icon="['fa-solid', 'fa-book-open']"></font-awesome-icon>
                </router-link>
            </div>
            <div class="col-6 mx-2">
                <input type="text" v-model="name">

            </div>



        </div>
        <div class="d-flex bg-success align-items-center text-white px-2 py-1" style="font-size: 16px;">
            <div class="col-3">အမည်</div>
            <div class="col-3">Code</div>
            <div class="col-2">နှုန်း</div>
            <div class="ms-auto">
                သင့်ငွေ
            </div>
            <div class="col-1">

            </div>
        </div>
        <div style="flex: 1;overflow-y: auto;" class="p-2">

            <div :key="key" v-for="( number, key ) in  numbers " class="d-flex align-items-center"
                style="font-size: 15px;font-weight: 700;">

                <div class="col-3">{{ number.name }}</div>

                <div class="col-3">{{ $2d.getMMName(number.number) }}</div>
                <div class="col-2">{{ number.amount }}</div>
                <div class="ms-auto">
                    {{ number.total_amount }}
                </div>
                <div class="col-1 text-end">
                    <font-awesome-icon @click="removeGroup(key)" class="ml-auto text-danger"
                        :icon="['fas', 'fa-trash']"></font-awesome-icon>
                </div>
            </div>
        </div>


        <div class="d-flex px-2 fw-bold">
            <div>
                Total
            </div>
            <div class="ms-auto" style="font-size: 1.3rem;">
                {{ totalBetAmount }}
            </div>
        </div>
        <div v-if="showKeyboard" class="keyboard-box mt-auto bg-success py-2" style="height: auto;">
            <div class="d-flex pb-2 px-2 align-items-center">
                <div class="col-4 px-1 border-none">
                    <input readonly ref="number" @focus="setFocusedElement('number')"
                        :class="{ 'focused-input': focusedInput == 'number' }" type="text" v-model="number"
                        class="w-100 number py-1">
                </div>
                <div class="col-6 px-1">
                    <input readonly ref="amount" @focus="setFocusedElement('amount')" type="text" v-model="amount"
                        :class="{ 'focused-input': focusedInput == 'amount' }" class="w-100 amount py-1">
                </div>
                <font-awesome-icon @click="showKeyboard = false" size="2xl" class="text-white ms-auto"
                    :icon="['fas', 'fa-circle-arrow-down']"></font-awesome-icon>

            </div>
            <div class="inner-wrapper">
                <div v-if="showModes" class="keyboard-wrapper px-2 mb-2">
                    <template v-for="( key, index ) in  modes " :key="index">
                        <div v-if="key.nod == number.length || (key.nod >= 3 && key.nod < number.length)"
                            @click.stop="handleKeyClick(key, $event)"
                            :class="{ 'number-key ': (key.type == 'key'), 'enter-key': key.type == 'enter', 'number-key': key.type == 'function' || key.type == 'del' || key.type == 'toggleShowMode', 'primary-color': key.color == 'primary', 'secondary-color': key.color != 'primary', 'col2': key.col == 2 }"
                            class="py-1" style="font-size: 20px;">
                            {{ key.name }}
                        </div>
                    </template>

                </div>
                <div class="keyboard-wrapper px-2 ">
                    <div @click.stop="handleKeyClick(key, $event)" v-for="( key, index ) in  keys " :key="index"
                        :class="{ 'number-key ': key.type == 'key' || key.type == 'toggleShowMode', 'enter-key': key.type == 'enter', 'number-key': key.type == 'function' || key.type == 'del', 'primary-color': key.color == 'primary', 'secondary-color': key.color != 'primary' }"
                        class="py-1">
                        {{ key.name }}
                    </div>
                </div>
            </div>

        </div>
        <div class="action-box mt-auto bg-success align-items-center p-2 d-flex">
            <div class="col-4 pe-1">
                <router-link :to="{ name: 'section.betting-list' }">
                    <SecondaryButton class="mx-1">စာရင်း</SecondaryButton>
                </router-link>
            </div>
            <div class="col-4 pe-1">
                <SecondaryButton @click="bet" class="mx-1">ထိုးမည်</SecondaryButton>
            </div>
            <div class="col-3 ps-1">
                <div class="fw-bold text-white">
                    <input type="checkbox">
                    Print
                </div>
            </div>
            <font-awesome-icon v-if="!showKeyboard" @click="showKeyboard = true" size="2xl" class="text-white ms-auto"
                :icon="['fas', 'fa-circle-arrow-up']"></font-awesome-icon>

        </div>


        <ModalBox :show="showModalBox" @close="showModalBox = false">
            <template v-slot:header>ထိုးမည့်ဂဏန်းများ</template>
            <template v-slot:body>
                <div class="row mb-2">
                    <div class="col-3 text-center">
                        <span class="fw-600 fs-12">နံပါတ်</span>
                    </div>
                    <div class="col-3 text-center">
                        <span class="fw-600 fs-12">ဆ</span>
                    </div>
                    <div class="col-4 text-center">
                        <span class="fw-600 fs-12">ကြေး</span>
                    </div>
                </div>
                <div class="row align-items-center pb-3" v-for="( number, index ) in  selectedNumbers " :key="index">
                    <div class="col-3">
                        <form-group class="fw-400 fs-14" :readOnly="true" :modelValue="number.number"
                            size="sm"></form-group>
                    </div>
                    <div class="col-3">
                        <form-group class="fw-400 fs-14" :readOnly="true" :modelValue="selectedSection.multiply"
                            size="sm"></form-group>
                    </div>
                    <div class="col-4">
                        <form-group class="fw-400 fs-14" v-model="number.amount" size="sm"></form-group>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                        <!-- <span style="width:7vw;aspect-ratio: 1/1;"
                            class="d-flex align-items-center justify-content-center bg-custom text-white rounded-circle me-2">
                            <font-awesome-icon icon="fa-solid fa-pencil" size="xs" />
                        </span> -->
                        <span @click="removeNumber(number)" style=""
                            class="d-flex align-items-center justify-content-center bg-danger text-white rounded-circle me-2">
                            <font-awesome-icon icon="fa-solid fa-trash" size="xs" />
                        </span>
                    </div>
                </div>
                <div class="horizontal-line"></div>

                <div class="d-flex mt-2">
                    <div class="col-6 pe-1">
                        <SecondaryButton class="fw-700 fs-14" @click="showModalBox = false">နောက်မှ</SecondaryButton>
                    </div>
                    <div class="col-6 ps-1">
                        <PrimaryButton @click="bet()" class="fw-700 fs-14">
                            ဆက်သွားမည်</PrimaryButton>
                    </div>
                </div>
            </template>
        </ModalBox>

        <ModalBox :show="showColorDescriptionModal" @close="showColorDescriptionModal = false">
            <template v-slot:header>အရောင်သတ်မှတ်ချက်</template>
            <template v-slot:body>
                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="fw-400 fs-16">
                            ??
                            <font-awesome-icon icon="fa-solid fa-circle" size="2xs"
                                class="position-absolute icon text-success" />
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">၅၀% အောက်</span>
                </div>

                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="fw-400 fs-16">
                            ??
                            <font-awesome-icon icon="fa-solid fa-circle" size="2xs"
                                class="position-absolute icon text-warning" />
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">၅၀% မှ ၉၀% ကြာ</span>
                </div>

                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="fw-400 fs-16">
                            ??
                            <font-awesome-icon icon="fa-solid fa-circle" size="2xs"
                                class="position-absolute icon text-danger" />
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">၉၀% အထက်</span>
                </div>

                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="full fw-400 fs-16">
                            ??
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">ထိုးငွေပြည့်သွားပါပြီ</span>
                </div>

            </template>
        </ModalBox>

    </div>
</template>
<script>
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
import moment from 'moment';
export default {
    components: {
        ModalBox
    },
    data() {
        return {
            selectedSection: {},
            showModalBox: false,
            showColorDescriptionModal: false,
            numbers: [
            ],
            betAmount: 0,
            showModes: false,
            selectedNumbers: [],
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            countDown: null,
            showKeyboard: true,
            name: null,
            modes: [
                {
                    name: 'ပါ၀ါ',
                    type: 'key',
                    action: 'W',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'အပါ',
                    type: 'key',
                    action: '+',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 1,

                },
                {
                    name: 'ထိပ်',
                    type: 'key',
                    action: '*',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 1,
                },
                {
                    name: 'နောက်',
                    type: 'key',
                    action: '*',
                    position: 0,
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 1,
                },
                {
                    name: 'ဘရိတ်',
                    type: 'key',
                    action: 'B',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 1,
                },
                {
                    name: 'မမ',
                    type: 'key',
                    action: 'MM',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'စုံစုံ',
                    type: 'key',
                    action: 'SS',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'စုံမ',
                    type: 'key',
                    action: 'SM',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'မစုံ',
                    type: 'key',
                    action: 'MS',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'စုံပူး',
                    type: 'key',
                    action: 'SP',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'မပူး',
                    type: 'key',
                    action: 'MP',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'စုံကပ်',
                    type: 'key',
                    action: 'S',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 1,

                },
                {
                    name: 'မကပ်',
                    type: 'key',
                    action: 'M',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 1,

                },


                {
                    name: 'ပြီးရှင်း',
                    type: 'key',
                    action: 'AP',
                    color: 'primary',
                    sub_type: 'mode',
                    col: 2,
                    nod: 3,

                },
                {
                    name: 'ပြီးရှင်း(ပူးမပါ)',
                    type: 'key',
                    action: 'APP',
                    color: 'primary',
                    sub_type: 'mode',
                    col: 2,
                    nod: 3,

                },
                {
                    name: 'ညီကို',
                    type: 'key',
                    action: 'NK',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'နက္ခတ်',
                    type: 'key',
                    action: 'K',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
                {
                    name: 'အပူး',
                    type: 'key',
                    action: 'P',
                    color: 'primary',
                    sub_type: 'mode',
                    nod: 0,

                },
            ],
            keys: [
                {
                    name: 'လိုတရ',
                    type: 'toggleShowMode',
                    color: 'primary',
                },
                {
                    name: '/',
                    type: 'key',
                    action: '/',
                    color: 'primary',
                    sub_type: 'mode'

                },
                {
                    name: '*',
                    type: 'key',
                    action: '*',
                    color: 'primary',
                    sub_type: 'mode'

                },
                {
                    name: 'Del',
                    type: 'del',
                    action: 'del',
                    color: 'primary',

                },
                {
                    name: '7',
                    type: 'key',
                    action: '7'
                },
                {
                    name: '8',
                    type: 'key',
                    action: '8'
                },
                {
                    name: '9',
                    type: 'key',
                    action: '9'
                },
                {
                    name: 'R',
                    type: 'key',
                    action: 'R'
                },

                {
                    name: '4',
                    type: 'key',
                    action: '4'
                },
                {
                    name: '5',
                    type: 'key',
                    action: '5'
                },
                {
                    name: '6',
                    type: 'key',
                    action: '6'
                },
                {
                    name: '-',
                    type: 'key',
                    action: '-'
                },
                {
                    name: '1',
                    type: 'key',
                    action: '1'
                },
                {
                    name: '2',
                    type: 'key',
                    action: '2'
                },
                {
                    name: '3',
                    type: 'key',
                    action: '3'
                },
                {
                    name: 'Enter',
                    type: 'enter',
                    action: 'enter'
                },
                {
                    name: '0',
                    type: 'key',
                    action: '0'
                },
                {
                    name: '00',
                    type: 'key',
                    action: '00'
                },
                {
                    name: '.',
                    type: 'key',
                    action: '.'
                },

            ],
            focusedInput: null,
            number: '',
            amount: null,
            clickSound: null,
            errorSound: new Audio(require('../../src/assets/error.mp3'))
        }
    },
    mounted() {
        let selectedSection = this.$route.params.section
        if (!selectedSection) {
            this.$router.push({ name: '2d' })
        }
        this.name = this.currentUser.name
        userService.get2DScheduleDetail(this.$route.params.section).then(({ data }) => {
            this.selectedSection = data.data
        })
        this.startCountDown()
    },
    computed: {
        totalBetAmount() {
            return this.numbers.reduce((total, value) => {
                return total + parseInt(value.total_amount);
            }, 0);
        }
    },
    methods: {
        removeGroup(index) {
            this.numbers.splice(index, 1)
        },
        addNumber() {
            if (this.amount.toString().includes('/')) {
                let amount1 = this.amount.split('/')[0];
                let amount2 = this.amount.split('/')[1];

                let n = parseInt(this.number);
                if (n.toString().length != 2) {
                    this.playErrorAudio()
                    this.$store.dispatch('showError', "ရိုက်ထည့်မှုမမှန်ကန်ပါ။")
                    return;
                }

                let number1 = this.number[0] + this.number[1];
                let number2 = this.number[1] + this.number[0];

                this.pushNumber(number1, amount1)
                this.pushNumber(number2, amount2)
            } else {
                if (this.number && this.amount) {
                    this.pushNumber(this.number, this.amount)
                }
            }

        },
        pushNumber(number, amount) {
            let value = this.$2d.formatValue(number + amount)
            if (value) {
                this.numbers.push({
                    name: this.name,
                    number: value.number,
                    amount: value.amount,
                    total_amount: value.total_amount,
                    numbers: value.numbers
                });

                this.value = ''
                this.previews = []
                this.number = ''
                this.$refs.number.focus()
                this.playEnterAudio()
                return
            }
            this.playErrorAudio()
            this.$store.dispatch('showError', "ရိုက်ထည့်မှုမမှန်ကန်ပါ။")
        },
        setFocusedElement(element) {
            this.focusedInput = element
        },
        handleKeyClick(key, event) {
            event.stopPropagation()
            switch (key.type) {
                case 'key': {

                    if (this.focusedInput == null) {
                        this.focusedInput = 'number'
                    }

                    if (this.focusedInput == 'number') {
                        if (this.number) {
                            let value;
                            if (key.position == 0) {
                                value = key.action + (this.number)
                            } else {
                                value = (this.number) + key.action
                            }
                            this.number = value
                        } else {
                            this.number = key.action
                        }
                    } else if (this.focusedInput == 'amount') {
                        if (this.amount) {
                            let value = (this.amount) + key.action
                            this.amount = value
                        } else {
                            this.amount = (key.action)
                        }
                        this.$refs.amount.focus()
                    }
                    if (key.sub_type == 'mode') {
                        this.focusedInput = 'amount'
                    }
                    this.playClickAudio()

                } break;

                case 'del': {
                    if (this.focusedInput == 'number') {
                        this.number = this.number.slice(0, -1)
                        this.$refs.number.focus()
                    }

                    if (this.focusedInput == 'amount') {
                        if (this.amount) {
                            this.amount = this.amount.toString().slice(0, -1)
                            this.$refs.amount.focus()
                        } else {
                            this.$refs.number.focus()

                        }

                    }
                    this.playClickAudio()

                } break;
                case 'toggleShowMode': {
                    this.showModes = !this.showModes

                } break;
                case "enter": {
                    if (this.focusedInput == 'number') {
                        if (this.amount && this.amount > 0) {
                            this.addNumber()
                        } else {
                            this.focusedInput = 'amount'
                            this.playClickAudio()

                        }
                    } else {
                        this.addNumber()
                    }
                }
            }
        },
        startCountDown() {
            var that = this;
            let coundDownInterval = setInterval(function () {
                var eventTime = moment(new Date(moment().format("YYYY-MM-DD") + ' ' + that.selectedSection.end_time)); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
                var currentTime = moment(new Date());
                var diffTime = eventTime - currentTime;
                if (diffTime < 1) {
                    that.countDown = "0 : 0 : 0";
                    clearInterval(coundDownInterval)
                } else {
                    var duration = moment.duration(diffTime, 'milliseconds');
                    that.countDown = (duration.hours().toString().padStart(2, '0') + " : " + duration.minutes().toString().padStart(2, '0') + " : " + duration.seconds())
                }
            }, 1000);
        },
        toggleNumber(number) {
            if (number.percent > 99.99) {
                return;
            }

            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });

            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            } else {
                this.selectedNumbers.push({
                    id: number.id,
                    number: number.number,
                    amount: this.betAmount
                })
            }
        },
        removeNumber(number) {
            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });
            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            }
        },
        round() {
            this.selectedNumbers.forEach(value => {
                let number = value.number;
                let round_number = this.numbers.find(n => {
                    return n.number == Number(String(number).split('').reverse().join(''))
                })

                let round_number_index = this.selectedNumbers.findIndex(n => {
                    return n.number == round_number.number
                });

                if (round_number_index == -1) {
                    this.selectedNumbers.push({
                        id: round_number.id,
                        number: round_number.number,
                        amount: this.betAmount
                    })
                }
            })
        },
        startBetting() {
            this.selectedNumbers.forEach((value, index) => {
                this.selectedNumbers[index].amount = this.betAmount
            })
            this.showModalBox = true
        },
        bet() {
            if (this.numbers.length <= 0) {
                this.playErrorAudio()
                this.$store.dispatch('showError', "ရိုက်ထည့်မှုမမှန်ကန်ပါ။")
                return;
            }
            this.$store.dispatch('loading')
            userService.bet2DSchedule(this.$route.params.section, {
                bet_data: this.numbers
            }).then(({ data }) => {
                console.log(data)
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    if (data.warning) {
                        this.playErrorAudio()
                        this.$store.dispatch('showError', data.warning)
                    } else {
                        this.$store.dispatch('showToast', data.message)
                        this.$store.commit('auth/subUserBalance', data.bet_amount)
                    }
                    this.numbers = []
                }
            })
                .catch(({ response }) => {
                    if (response.status == 403) {
                        this.playErrorAudio()

                        this.$store.dispatch('showError', response.data.message)
                    }
                })
        },
        playClickAudio() {
            this.clickSound = new Audio(require('../../src/assets/click.mp3'));
            this.clickSound.play()
        },
        playErrorAudio() {
            this.errorSound = new Audio(require('../../src/assets/error.mp3'))
            this.errorSound.play()
        },
        playEnterAudio() {
            let enterSound = new Audio(require('../../src/assets/enter.wav'))
            enterSound.play()
        }
    },
}

</script>
<style>
.keyboard-wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
}

.keyboard-wrapper>.primary-color {
    background-color: #20c997 !important;
    color: white;
}

.keyboard-wrapper>.secondary-color {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.keyboard-wrapper>.number-key {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #000;
}

.col2 {
    grid-column: span 2;
}

.keyboard-wrapper>.enter-key {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #000;
    grid-row: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.two-d-section .bannr {
    color: #fff;
    background-color: #20c997;
}

/* .two-d-section .content {
    height: 72vh;
    overflow: auto;
} */

.two-d-section .number {
    width: 20%;
}

.number div {
    background-color: #F1F3FA;
    color: #000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inner-wrapper {
    max-height: 40vh;
    overflow-y: auto;
}

.keyboard-box .key {
    background-color: white;
    width: 100%;
    font-size: 20px;

    font-weight: bold;
}

.number .icon {
    bottom: 5px;
    font-size: .4rem;
}

.number .full {
    background-color: transparent !important;
    color: #9e9e9e !important;
    border: 1px solid #F1F3FA;

}

.number .choosed {
    background-color: #20c997 !important;
    color: #fff !important;
}

.two-d-section .bet-amount {
    width: 100vw;
    border-bottom: 1px solid #F1F3FA;
    z-index: 1000;
}

.bet-amount input {
    border: none;
    outline: none;
    background-color: transparent;
}

input.number,
input.amount {
    border: none;
    outline: none;
    padding: 4px 10px !important;
    border-radius: 10px;
    font-size: 20px;
}

.number,
.amount {
    border: 2px solid rgb(134, 127, 127) !important;
}

.focused-input {
    border: 2px solid rgb(9, 14, 9) !important;
}
</style>